import logo from './logo.svg';
import { useEffect, useState, useRef } from "react"
import './App.css';
import { GetData, GetTags } from "./DataProvider"
import Tags from "./Components/Tags"
import { FaCheck } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';


function App() {
  const [doNotDisplay, updateDoNotDisplay] = useState(false);
  const [questionForm, updateQuestionForm] = useState(false);
  const [askedQuestions, updateAskedQuestion] = useState(false);
  const clasifications = ["Probleme", "Indicatii", "Informare", "Neintelegere", "Feedback negativ"];
  const colors = ["#727aaf", "#a3ac6c", "#86d2da", "#b8de85", "#6eab92", "#86dea9", "#9270b1", "#dccf84", "#6caf79", "#7db06d", "#df86ca", "#e087a6", "#e08b88", "#e1ad86", "#87a4df", "#86e088", "#6cafa7", "#6d9daf", "#8e89e3", "#b088e1", "#ab70b2", "#b16e80", "#b1776e", "#ae906c", "#af6d99", "#d387e0", "#d486e2", "#a57d8b", "#b26b9f", "#a58bc8"]
  if (!askedQuestions) {
    updateAskedQuestion(true)
    if (localStorage.getItem("annotations")) {
      updateQuestionForm(true);
    }
  }
  const [name, updateName] = useState("");
  const inputFile = useRef(null);
  const [annotations, updateAnnotations] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [loadingTags, updateLoadingTags] = useState(true);
  const [data, updateData] = useState([])
  const [clasification, updateClasification] = useState([])
  const [currentIndex, updateIndex] = useState(0);
  const types = ["expresii ascultare activa", "de confirmare", "hate/offensive speech", "empatice", "manifestare flexibila", "recomdare", "echipament/solutii Freya", "revenire", "perioade de timp", "salut", "incheiere apel", "nume", "ORG", "LOC", "neintelegere"]
  const [currentType, updateType] = useState(0)
  const [markedData, updateMarkedData] = useState([])
  const [startSelect, updateStart] = useState(null);
  const [endSelect, updateEnd] = useState(null);
  const [selectedTag, updateSelectedTag] = useState([]);


  useEffect(() => {
    GetData()
      .then((result) => {
        updateData(result);
        updateLoading(false);
      })
      .catch(error => {

      })

    if (window.innerWidth < 767) {
      updateDoNotDisplay(true);
    } else {
      updateDoNotDisplay(false);
    }
  }, [])

  useEffect(() => {
    saveData();
  }, [markedData, clasification])

  const doSomethingBeforeUnload = async () => {
    // Do something
    localStorage.setItem("annotations", JSON.stringify(annotations))
    localStorage.setItem("file", JSON.stringify(data))
    localStorage.setItem("currentIndex", currentIndex);
    localStorage.setItem("markedData", JSON.stringify(markedData))
    localStorage.setItem("clasification", JSON.stringify(clasification))
  }

  window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    return doSomethingBeforeUnload();

  });

  useEffect(() => {
    if (startSelect == null && endSelect != null) {
      updateEnd(null);
    } else if (startSelect != null && endSelect != null && startSelect != endSelect) {
      let start = startSelect > endSelect ? endSelect : startSelect
      let end = startSelect > endSelect ? startSelect : endSelect;
      if (markedData.some(data => data.start >= start && data.end <= end ||
        start >= data.start && start <= data.end ||
        end >= data.start && end <= data.end)) {
      } else {
        updateMarkedData([...markedData, {
          start,
          end,
          type: currentType
        }])
      }

    }
  }, [endSelect])

  useEffect(() => {
    let targetSample = annotations.find(object => object.index == currentIndex);
    console.log("aici")
    if (targetSample) {
      updateSelectedTag(targetSample.selectedTag)
      updateMarkedData(targetSample.markedData)
      updateClasification(targetSample.clasification || [])
    } else {
      updateSelectedTag([])
      updateMarkedData([])
      updateClasification([])
    }
  }, [currentIndex])


  //Type switch
  document.onkeydown = function (e) {
    console.log(e);
    if ("0123456789".includes(e.key)) {
      let index = parseInt(e.key);
      if (index >= 1 && index <= types.length) {
        updateType(index - 1);
      }
    } else if ("zZxX".includes(e.key)) {
      if ("zZ".includes(e.key)) {
        markValidity(true);
      } else {
        markValidity(false);
      }
    } else if (e.key == " ") {
      next();
    } else if (e.code == "ControlLeft") {
      prev();
    } else if ("asdfg".includes(e.key)) {
      markClasifier("asdfg".indexOf(e.key))
    }
  };

  const isInMarked = (index) => {
    return markedData.some(data => data.start <= index && data.end >= index)
  }
  const getMarkedByIndex = (index) => {
    return markedData.find(data => data.start <= index && data.end >= index)
  }

  const getTypeOfMarked = (index) => {
    return markedData.find(data => data.start <= index && data.end >= index).type
  }

  const removeFromMarked = (index) => {
    updateMarkedData(marked => {
      return marked.filter(data => !(data.start <= index && data.end >= index));
    })
  }

  const next = () => {
    if (getCurrentValidity() == 0) {
      return;
    }
    saveData();
    updateIndex(currentIndex + 1);
  }

  const prev = () => {
    if (currentIndex == 0) {
      return;
    }
    saveData();
    updateIndex(currentIndex - 1);
  }


  const saveData = () => {
    if (data == null || data.length == 0) {
      return;
    }
    let targetSample = annotations.find(object => object.index == currentIndex)
    if (targetSample) {
      updateAnnotations(annotations.map(object => (object.index == currentIndex) ? {
        ...object,
        selectedTag,
        markedData,
        clasification
      } : object))
    } else {
      updateAnnotations([...annotations, {
        index: currentIndex,
        selectedTag,
        markedData,
        id: data[currentIndex].id,
        data: data[currentIndex],
        clasification
      }])
    }
  }

  const getCompletedToken = (index, token) => {
    if (!isInMarked(index)) {
      return token;
    } else {
      let marked = getMarkedByIndex(index);
      if (marked.start == marked.end) {
        return token;
      } else {
        return data[currentIndex].tokens.slice(marked.start, marked.end + 1).reduce((acc, x) => acc + " " + x, "");
      }
    }
  }

  const markValidity = (validity) => {
    let targetSample = annotations.find(object => object.index == currentIndex)
    if (targetSample) {
      updateAnnotations(annotations.map(object => (object.index == currentIndex) ? {
        ...object,
        selectedTag,
        markedData,
        validity,
        clasification
      } : object))
    } else {
      updateAnnotations([...annotations, {
        index: currentIndex,
        selectedTag,
        markedData,
        validity,
        clasification
      }])
    }
    // next();
  }

  const Words = () => {
    return data[currentIndex].tokens.map((x, index) => { let res = { value: x, index }; return res }).filter((token, index) => {
      return !markedData.some(data => (data.start <= index - 1 && data.end >= index))
    }).map((token, index) => {
      return (<div onMouseDown={(e) => updateStart(token.index)}
        onMouseUp={() => updateEnd(token.index)}
        onClick={handleClick(token.index)}
        style={{backgroundColor: isInMarked(token.index) ?colors[getTypeOfMarked(token.index)]:"none"}}
        className={'word-box' + (isInMarked(token.index) ? " marked prevent-select" : "")}>
        {getCompletedToken(token.index, token.value)}{isInMarked(token.index) ? <><div className='close-marked prevent-select' onMouseDown={(e) => e.stopPropagation()} onMouseUp={e => e.stopPropagation()} onClick={(e) => { e.stopPropagation(); e.preventDefault(); removeFromMarked(token.index); }}>X</div>
          <div className='marked-name'>{types[getTypeOfMarked(token.index)]}</div></> : ""}</div>)
    })
  }

  const markClasifier = (index) => {
    updateClasification(currentClasification => (currentClasification.some(c => c == index) ? currentClasification.filter(x => x != index) : [...currentClasification, index]))
  }

  const isActiveClasifier = (index) => clasification.some(x => x == index)

  const Clasifier = () => (<div className='clasification-block'>
    {clasifications.map((variant, index) => {
      return (<div onClick={() => markClasifier(index)} className={'clasification' + (isActiveClasifier(index) ? " active-clasfier" : "")}>{variant}<div className='suggestion-clasifier'>press {"asdfg"[index]}</div></div>)
    })}</div>)





  const handleClick = (index) => (e) => {
    if (isInMarked(index))
      return;
    if (e.detail == 2) {
      updateMarkedData(marked => {
        if (marked.some(data => data.start <= index && data.end >= index)) {
        } else {
          return [...marked, {
            start: index, end: index, type: currentType
          }]
        }
      })
    }
  };

  const fileSelect = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onImportFileChange = (e) => {
    console.log("bla")
    let file = e.target.files[0];
    console.log(file);
    if (file) {
      try {
        updateLoading(true);
        const fileReader = new FileReader();
        fileReader.readAsText(file, "UTF-8");
        fileReader.onload = e => {
          console.log("e.target.result", e.target.result);
          let data = JSON.parse(e.target.result);
          if (data.some(element => element.tokens == undefined)) {
            alert("Imported data has wrong format, missing 'tokens' field")
          }
          e.target.value = null;
          if (window.confirm("Are you sure you want to replace all data?")) {
            let annotations = []
            if (data.some(x => x.markedData))
              annotations = data.reduce((acc, x) => x.markedData ? [...acc, { index: x.index, id: x.id, markedData: x.markedData, validity: x.validity, clasification: x.clasification }] : acc, [])
            updateData(data);
            updateMarkedData(annotations.find(x => x.index == 0)?.markedData || []);
            updateIndex(0);
            updateAnnotations(annotations);
            updateClasification(annotations.find(x => x.index == 0)?.clasification || [])
            updateLoading(false);
          }
        };
      } catch (error) {
        console.log(error);
      }
    }
  }

  const loadDataFromLocalStorage = () => {
    updateData(JSON.parse(localStorage.getItem("file")));
    updateAnnotations(JSON.parse(localStorage.getItem("annotations")));
    updateIndex(parseInt(localStorage.getItem("currentIndex")));
    updateMarkedData(JSON.parse(localStorage.getItem("markedData")));
    updateClasification(JSON.parse(localStorage.getItem("clasification")));
    updateQuestionForm(false);
  }

  const getCurrentValidity = () => {
    let targetSample = annotations.find(object => object.index == currentIndex)
    if (targetSample) {
      if (targetSample.validity == true) {
        return 1;
      } else if (targetSample.validity == false) {
        return 2;
      }
    }
    return 0;
  }

  const exportData = () => {
    let finalObject = [...data]
    console.log(annotations)

    finalObject = finalObject.map(currentObject => {
      let anotatedObject = annotations.find(anoObj => anoObj.id == currentObject.id);
      if (anotatedObject) {
        console.log(anotatedObject)
        let ner_tags = new Array(data[anotatedObject.index].tokens.length).fill("O")
        let ner_ids = new Array(data[anotatedObject.index].tokens.length).fill(0)
        anotatedObject.markedData.forEach(element => {
          for (let i = element.start; i <= element.end; i++) {
            if (i == element.start) {
              ner_tags[i] = "B-" + types[element.type];
              ner_ids[i] = (element.type * 2) + 1;
            } else {
              ner_tags[i] = "I-" + types[element.type];
              ner_ids[i] = (element.type * 2) + 2;
            }
          }
        });
        return ({ ...currentObject, id: anotatedObject.id, clasification: anotatedObject.clasification, ner_tags, tokens: data[anotatedObject.index].tokens, validity: anotatedObject.validity, ner_ids, markedData: anotatedObject.markedData, index: anotatedObject.index })
      } else {
        delete currentObject.ner_tags;
        delete currentObject.ner_ids;
        return currentObject;
      }
    })



    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(finalObject)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "export_annotated_by_" + name + ".json";

    link.click();
  }

  return (
    <div className="App">
      {doNotDisplay && <div className='too-small'>
        This application was developed for desktop and laptop, please use a display with minimum 767px
      </div>
      }
      <div className='sidebar'>
        <div className='username'>Anotator X</div>
      </div>
      <div className='work-box'>
        <div className='main-box'>
          {
            loading || <div className='current-level prevent-select'>{currentIndex + 1}/{data.length}<div className='completed'>
              ({annotations.reduce((acc, x) => x.markedData.length > 0 ? acc + 1 : acc, 0)})
            </div></div>
          }
          <div className='class-words'>
            {
              types.map((type, index) => {
                return (<div style={{backgroundColor: colors[index]}} onClick={() => updateType(index)} className={'type prevent-select' + (index == currentType ? " selected-type" : "")}>{type}
                </div>)
              })
            }
          </div>
          <div className='words'>
            <div className='speaker'>Speaker: {data[currentIndex]?.speaker || " unavalaible"}</div>

            {loading ? 'loading' : <Words />}
          </div>

          <div className='action-buttons prevent-select'>
            <div onClick={prev} className={'action-button prev ' + (currentIndex == 0 ? " disabled-button" : "")} >Prev</div>
            <Clasifier />
            <div onClick={next} className={'action-button next ' + (currentIndex == data?.length - 1 ? " disabled-button" : "") + (getCurrentValidity() == 0 ? " disabled-button" : "")}>Next</div>
          </div>
        </div>
      </div>
      <div></div>
      <div id='validation-box'>
        <div onClick={() => markValidity(true)} className={'accept' + (getCurrentValidity() == 1 ? " active-accept" : "")}><FaCheck /></div>
        <div onClick={() => markValidity(false)} className={'deny' + (getCurrentValidity() == 2 ? " active-deny" : "")}><FaXmark /></div>
      </div>
      <div onClick={exportData} className='export prevent-selection'>
        Export Data
        <input value={name} onChange={(e) => updateName(e.target.value)} onClick={(e) => e.stopPropagation()} className='adno-name' type='text' placeholder='Nume adnotator'></input>
      </div>
      <input type='file' id='file' accept=".json" hidden ref={inputFile} onChange={onImportFileChange} style={{ display: 'none' }} />

      <div onClick={fileSelect} className='import prevent-selection'>
        Import file
      </div>
      {loading && "Se incarca"}
      {
        questionForm && <div className='continue-from-last'>
          <div className='question'><strong>We detected that you unfinished your last session</strong><hr />Do you want to continue your previous session? <br />
            <small>It will load file you imported and anotated data</small></div>
          <div className='question-responses'>
            <div className='question-yes' onClick={loadDataFromLocalStorage}>Yes</div>
            <div className='question-no' onClick={() => window.confirm("This action will clean previous session") && updateQuestionForm(false)}>No</div>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
