import data from "./DataDummy.json"

const GetData = async () => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(data.slice(0, 100)), 1000)
    })
}

const GetTags = async () => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(["expresii ascultare activa","de confirmare","hate/offensive speech","empatice","manifestare flexibila","recomdare","echipament/solutii Freya","revenire","perioade de timp","salut","incheiere apel","nume","ORG","LOC","neintelegere"]), 1000)
    })
}

export  {GetData, GetTags}






